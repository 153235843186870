'use client';

import Link from 'next/link';
import Icon from '#/components/icon';

export default function NotFound() {
  const links = [
    {
      title: 'Pro Themes',
      href: '/themes/',
      icon: 'pro-themes',
      description: 'Premium Ghost CMS Themes',
    },
    {
      title: 'Documentation',
      href: '/docs/',
      icon: 'doc',
      description: 'Detailed documentation for each premium Ghost theme',
    },
    {
      title: 'Changelog',
      href: '/changelog/',
      icon: 'changelog',
      description: 'All the information you need about the theme updates',
    },
    {
      title: 'Blog',
      href: '/blog/',
      icon: 'blog',
      description: 'Get the latest Ghost CMS news & updates',
    },
  ];

  return (
    <div className="mx-auto max-w-5xl px-4">
      <div className="max-w-5xl py-20">
        <h1 className="text-center text-3xl font-normal tracking-tight text-accent-txt-1 sm:text-4xl md:text-5xl">
          404 - not found
        </h1>
        <p className="mt-4 text-center text-accent-txt-2 md:text-lg">
          Could not find requested resource
        </p>
      </div>

      <div className="mx-auto mt-8 flow-root max-w-lg sm:mt-12">
        <ul
          role="list"
          className="-mt-6 divide-y divide-accent-brd border-y border-accent-brd"
        >
          {links.map((link) => (
            <li className="" key={link.title}>
              <Link
                href={link.href}
                className="group relative flex gap-x-4 py-6 transition-all hover:bg-accent-bg-2 hover:px-4 focus:px-4"
              >
                <div className="mt-1 flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-accent-bg-2 border border-accent-brd p-2 group-hover:bg-accent-bg-1">
                  <Icon
                    name={link.icon}
                    className="h-6 w-6 text-secondary"
                    strokeWidth="1.5"
                  />
                </div>
                <div className="flex-auto">
                  <h2 className="text-base font-normal leading-6 text-accent-txt-1">
                    {link.title}
                  </h2>
                  <p className="text-sm leading-6 text-accent-txt-2 opacity-90">
                    {link.description}
                  </p>
                </div>
                <div className="flex-none self-center">
                  <Icon
                    name="chevron-right"
                    className="h-4 w-4 text-accent-txt-2 opacity-60"
                  ></Icon>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <div className="mb-20 mt-10 flex justify-center">
          <Link
            href="/"
            className="flex items-center rounded-full border border-accent-txt-1 px-4 py-1 pl-3 text-sm font-normal leading-6 text-accent-txt-1 hover:bg-accent-txt-1 hover:text-accent-bg-1"
          >
            <Icon
              name="chevron-right"
              className="mr-1 h-4 w-4 rotate-180"
            ></Icon>
            Go back home
          </Link>
        </div>
      </div>
    </div>
  );
}
